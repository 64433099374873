import './Content.scss';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import ContentError from './ContentError';
import ContentInfo from './ContentInfo';
import ContentResources from './ContentResources';
import ContentWatchResources from './ContentWatchResources';
import { getProvidersProps } from 'dhub-config';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import React from 'react';

const providersUrls = getProvidersProps(['urls']);

/**
 * This component fetches the data for the media passed and renders a container with an instance of ContentInfo (media info)
 * and ContentResources (list of resources).
 * It also renders a backdrop image.
 */
function Content(props) { // eslint-disable-line max-lines-per-function
    const [details, setDetails] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [fullImg, setFullImg] = React.useState(false);
    const [relatedResources, setRelatedResources] = React.useState(null);

    function handleImgClick() {
        setFullImg(!fullImg);
    }


    React.useEffect(() => {
        const hideFullImg = () => setFullImg(false);
        window.addEventListener('resize', hideFullImg);

        document.getElementById('logo').style.position = 'absolute';
        document.getElementById('searchBar').style.position = 'absolute';

        return () => window.removeEventListener('resize', hideFullImg);
    }, []);

    React.useEffect(() => {
        setFullImg(false);
        window.scrollTo(0, 0);
    }, [props.match.params]);

    React.useEffect(() => {
        const abortController = new AbortController();

        fetch(`${DOWNLOAD_HUB_SERVER_URL}${DOWNLOAD_HUB_SERVER_API.mediaDetails}`
        + `?id=${encodeURIComponent(props.match.params.id)}`
        + `&type=${encodeURIComponent(props.match.params.type)}`
        + `&lang=${encodeURIComponent(props.match.params.locale?.slice(0, 2))}`, {
            signal: abortController.signal,
            timeout: DOWNLOAD_HUB_REQ_TIMEOUT
        }).then((res) => res.json()).then((res) => {
            res.type = props.match.params.type;
            return setDetails(res);
        }).catch((err) => {
            setDetails(null);
            setError(err);
        });

        return () => abortController.abort();
    }, [props.match.params]);

    React.useEffect(() => {
        const abortController = new AbortController();

        fetch(`${DOWNLOAD_HUB_SERVER_URL}${DOWNLOAD_HUB_SERVER_API.mediaRelatedResources}`
        + `?id=${encodeURIComponent(props.match.params.id)}`
        + `&type=${encodeURIComponent(props.match.params.type)}`, {
            signal: abortController.signal,
            timeout: DOWNLOAD_HUB_REQ_TIMEOUT
        }).then((res) => res.json()).then((res) => {
            // Remove resources from providers that are not configured
            const filteredRes = res.filter(({ origin }) => Object.keys(providersUrls).includes(origin));

            // Special behavior to generate alternative versions of resources with multiple urls
            filteredRes.forEach((resource) => {
                for (let i = 1; i < providersUrls[resource.origin].length; i += 1) {
                    filteredRes.push({ ...resource, alt: i });
                }
            });

            return setRelatedResources(filteredRes);
        }).catch((err) => {
            setRelatedResources(null);
            setError(err);
        });

        return () => abortController.abort();
    }, [props.match.params]);


    if (error) {
        return <ContentError />;
    } else if (!details || !relatedResources) {
        return (
            <div className='content'>
                <AiOutlineLoading3Quarters />
            </div>
        );
    }

    return (
        <div className={`content${fullImg ? ' fullImg' : ''}`}>
            <Helmet>
                <title>{details.title || details.name}</title>
            </Helmet>

            {details.backdrop_path && (
                <div className='contentImg' onClick={handleImgClick}>
                    <img src={`${DOWNLOAD_HUB_TMDB_API.backdropImg}${details.backdrop_path}`} alt='' />
                </div>
            )}

            <ContentInfo details={details}>
                <ContentWatchResources id={props.match.params.id} type={props.match.params.type} />
            </ContentInfo>

            <ContentResources key={`${props.match.params.type}-${props.match.params.id}`} resources={relatedResources} />
        </div>
    );
}

Content.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
            locale: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired
        }).isRequired
    }).isRequired
};

export default Content;