import './SearchBar.scss';

import { MdSearch } from 'react-icons/md';
import PropTypes from 'prop-types';
import React from 'react';
import SearchBarResults from './SearchBarResults';
import { withTranslation } from 'react-i18next';

/**
 * This component renders a form with an input field and an instance of SearchBarResults (list of search results).
 * The list of results is fetched here on form submission and on input change.
 */
class SearchBar extends React.Component {
    static propTypes = {
        i18n: PropTypes.objectOf(PropTypes.any).isRequired, // eslint-disable-line react/forbid-prop-types
        t: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);

        this.opts = DOWNLOAD_HUB_SEARCHBAR_OPTS;
        this.fetchTimeout = null; // Timeout to delay the fetch operation on input change and on scrollTopMax reached
        this.blurTimeout = null; // Timeout to ensure the focus is not lost on form children

        this.state = {
            focus: false,
            fullSize: false,
            nextPage: {
                movie: 0,
                tv: 0
            },
            query: '',
            results: [],
            status: 0
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleScroll = this.handleScroll.bind(this);

        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.displayResults = this.displayResults.bind(this);
        this.getMatches = this.getMatches.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }


    handleChange(e) {
        this.setState({ nextPage: { movie: 0, tv: 0 }, results: [], status: 1, [e.target.name]: e.target.value });

        clearTimeout(this.fetchTimeout);
        this.fetchTimeout = setTimeout(() => this.getMatches(), this.opts.fetchDelay);
    }

    handleSubmit(e) {
        e.preventDefault();

        if (this.state.query.length > this.opts.minLength) {
            this.setState({ fullSize: true });
            document.getElementsByClassName('searchBarResults')[0].firstChild.scrollTo(0, 0);
        }
    }

    handleScroll(e) {
        if (this.state.status !== 1
        && (this.state.nextPage.movie < this.opts.pageLimit || this.state.nextPage.tv < this.opts.pageLimit)
        && e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
            this.setState({ status: 1 });

            clearTimeout(this.fetchTimeout);
            this.fetchTimeout = setTimeout(() => this.getMatches(), this.opts.fetchDelay);
        }
    }


    handleFocus() {
        clearTimeout(this.blurTimeout);
        this.setState({ focus: true });
    }

    handleBlur() {
        this.blurTimeout = setTimeout(() => this.setState({ focus: false, fullSize: false }));
    }

    handleKeyDown(e) {
        if (e.keyCode === 27) { // Esc key
            e.target.blur();
            this.handleBlur();
        }
    }

    handleClick(e) { // Hides the searchBarResults container after clicking on a result
        if (e.target.href) {
            this.handleBlur();
        }
    }


    displayResults() {
        return this.state.focus && this.state.query.length > this.opts.minLength;
    }

    async getMatches() {
        if (!this.displayResults()) {
            return;
        }

        const defaultEmptyRes = { pagesLeft: 0, results: [] };
        const movieRes = this.state.nextPage.movie < this.opts.pageLimit ? await this.fetchData('movie') : defaultEmptyRes;
        const tvRes = this.state.nextPage.tv < this.opts.pageLimit ? await this.fetchData('tv') : defaultEmptyRes;

        if (!movieRes && !tvRes) {
            return;
        }

        this.setState((prevState) => ({
            nextPage: { // Stops loading new pages when there's no more pages left
                movie: movieRes.pagesLeft > 0 ? prevState.nextPage.movie + 1 : this.opts.pageLimit,
                tv: tvRes.pagesLeft > 0 ? prevState.nextPage.tv + 1 : this.opts.pageLimit
            },
            results: prevState.results.concat([...movieRes.results, ...tvRes.results].sort(({ popularity: a }, { popularity: b }) => b - a)),
            status: 0
        }));
    }

    async fetchData(type) {
        try {
            const res = await (await fetch(`${DOWNLOAD_HUB_SERVER_URL}${DOWNLOAD_HUB_SERVER_API.search}`
            + `?query=${encodeURIComponent(this.state.query)}`
            + `&type=${encodeURIComponent(type)}`
            + `&page=${encodeURIComponent(this.state.nextPage[type])}`
            + `&lang=${encodeURIComponent(this.props.i18n.language.slice(0, 2))}`, {
                timeout: DOWNLOAD_HUB_REQ_TIMEOUT
            })).json();

            res.results = res.results.filter((a) => !this.state.results.find((b) => a.id === b.id && a.type === b.type));

            return res;
        } catch (error) {
            return this.setState({ status: -1 });
        }
    }


    render() {
        return (
            <>
                <form id='searchBar' className='searchBar' autoCapitalize='off' autoComplete='off' onSubmit={this.handleSubmit}
                    onFocus={this.handleFocus} onKeyDown={this.handleKeyDown} onClick={this.handleClick}
                >
                    <label>
                        <MdSearch />

                        <input className={!this.state.fullSize && this.displayResults() ? 'openBottom' : null} type='search'
                            name='query' placeholder={this.props.t('Search')} value={this.state.query} maxLength={50}
                            tabIndex={0} autoCorrect='off' spellCheck='false' autoFocus onChange={this.handleChange} />
                    </label>

                    {this.displayResults()
                        && <SearchBarResults fullSize={this.state.fullSize} results={this.state.results} status={this.state.status}
                            onScroll={this.handleScroll} />}
                </form>

                {this.displayResults() && <div className='searchBarInterceptor' onClick={this.handleBlur} />}
            </>
        );
    }
}

export default withTranslation()(SearchBar);