import './ContentInfo.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * This component renders a container with useful information of the media.
 */
function ContentInfo(props) { // eslint-disable-line complexity, max-lines-per-function
    const { i18n, t } = useTranslation();
    const genres = [];
    const originalTitle = props.details.original_title || props.details.original_name;
    const prodCompanies = [];
    const prodCountries = [];
    const title = props.details.title || props.details.name;
    let episodeDuration = null;

    if (props.details.episode_run_time && props.details.episode_run_time.length > 0) {
        episodeDuration = Math.floor(props.details.episode_run_time
            .reduce((acc, time) => acc + time) / props.details.episode_run_time.length);
    }

    if (props.details.genres) {
        props.details.genres.forEach((genre) => genre.name && genres.push(genre.name));
    }

    if (props.details.production_companies) {
        props.details.production_companies.forEach((company) => company.name
            && prodCompanies.push(`${company.name}${company.origin_country && ` (${company.origin_country})`}`));
    }

    if (props.details.production_countries) {
        props.details.production_countries.forEach((country) => country.name && prodCountries.push(country.name));
    }

    return (
        <div className='contentInfo'>
            {props.details.poster_path && <img src={`${DOWNLOAD_HUB_TMDB_API.posterImgL}${props.details.poster_path}`} alt='' />}

            {title && <h1>{title}</h1>}

            {props.details.tagline && <p className='tagline'>{props.details.tagline}</p>}

            {props.details.overview && <p>{props.details.overview}</p>}

            <div className='contentInfoDetails'>
                {originalTitle && (
                    <div>
                        <h2>{t('Original Title')}</h2>

                        <p>{originalTitle}</p>
                    </div>
                )}

                {(props.details.vote_average > 0 && props.details.vote_count > 0) && (
                    <div>
                        <h2>{t('Score')}</h2>

                        <p>
                            {`${props.details.vote_average} (${props.details.vote_count}`
                                + ` ${t(props.details.vote_count === 1 ? 'vote' : 'votes')})`}
                        </p>
                    </div>
                )}

                {props.details.type && (
                    <div>
                        <h2>{t('Type')}</h2>

                        <p>{props.details.type === 'movie' ? t('Movie') : t('Series')}</p>
                    </div>
                )}

                {props.details.status && (
                    <div>
                        <h2>{t('Status')}</h2>

                        <p>{t(props.details.status)}</p>
                    </div>
                )}

                {props.details.number_of_seasons > 0 && (
                    <div>
                        <h2>{t('Seasons')}</h2>

                        <p>{props.details.number_of_seasons}</p>
                    </div>
                )}

                {props.details.number_of_episodes > 0 && (
                    <div>
                        <h2>{t('Episodes')}</h2>

                        <p>{props.details.number_of_episodes}</p>
                    </div>
                )}

                {props.details.runtime > 0 && (
                    <div>
                        <h2>{t('Duration')}</h2>

                        <p>{`${props.details.runtime} ${t(props.details.runtime === 1 ? 'minute' : 'minutes')}`}</p>
                    </div>
                )}

                {episodeDuration > 0 && (
                    <div>
                        <h2>{t('Episode Duration')}</h2>

                        <p>{`${episodeDuration} ${t(episodeDuration === 1 ? 'minute' : 'minutes')}`}</p>
                    </div>
                )}

                {props.details.release_date && (
                    <div>
                        <h2>{t('Release Date')}</h2>

                        <p>{new Date(props.details.release_date).toLocaleDateString(i18n.language)}</p>
                    </div>
                )}

                {props.details.first_air_date && (
                    <div>
                        <h2>{t('First Air Date')}</h2>

                        <p>{new Date(props.details.first_air_date).toLocaleDateString(i18n.language)}</p>
                    </div>
                )}

                {genres.length > 0 && (
                    <div>
                        <h2>{t(genres.length === 1 ? 'Genre' : 'Genres')}</h2>

                        <p>{genres.join(', ')}</p>
                    </div>
                )}

                {prodCountries.length > 0 && (
                    <div>
                        <h2>{t(prodCountries.length === 1 ? 'Production Country' : 'Production Countries')}</h2>

                        <p>{prodCountries.join('\n')}</p>
                    </div>
                )}

                {prodCompanies.length > 0 && (
                    <div>
                        <h2>{t(prodCompanies.length === 1 ? 'Production Company' : 'Production Companies')}</h2>

                        <p>{prodCompanies.join('\n')}</p>
                    </div>
                )}
            </div>

            {props.children}
        </div>
    );
}

ContentInfo.propTypes = { /* eslint-disable camelcase */
    children: PropTypes.node.isRequired,
    details: PropTypes.shape({
        episode_run_time: PropTypes.arrayOf(PropTypes.number),
        first_air_date: PropTypes.string,
        genres: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string
        })),
        name: PropTypes.string,
        number_of_episodes: PropTypes.number,
        number_of_seasons: PropTypes.number,
        original_name: PropTypes.string,
        original_title: PropTypes.string,
        overview: PropTypes.string,
        poster_path: PropTypes.string,
        production_companies: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            origin_country: PropTypes.string
        })),
        production_countries: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string
        })),
        release_date: PropTypes.string,
        runtime: PropTypes.number,
        status: PropTypes.string,
        tagline: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
        vote_average: PropTypes.number,
        vote_count: PropTypes.number
    }).isRequired
}; /* eslint-enable camelcase */

export default ContentInfo;