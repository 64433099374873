import './SearchBarResultsRow.scss';

import { FaRegImage, FaTv } from 'react-icons/fa';

import { FiFilm } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * This component renders each result, displaying all its data passed.
 */
function SearchBarResultsRow(props) { // eslint-disable-line max-lines-per-function
    const [img, setImg] = React.useState(`${DOWNLOAD_HUB_TMDB_API[props.fullSize ? 'posterImgM' : 'posterImgS']}${props.result.img}`);

    const { i18n, t } = useTranslation();

    function handleImgError() {
        if (!img.includes('?reload=true')) {
            setImg(`${img}?reload=true`);
        }
    }


    React.useEffect(() => {
        setImg(`${DOWNLOAD_HUB_TMDB_API[props.fullSize ? 'posterImgM' : 'posterImgS']}${props.result.img}`);
    }, [props.fullSize, props.result.img]);


    return props.fullSize
        ? (
            <Link className='searchBarResultsRow fullSize' to={`/${i18n.language}/${props.result.type}/${props.result.id}`} tabIndex={0}>
                <div>
                    {props.result.type === 'movie' ? <FiFilm title={t('Movie')} /> : <FaTv title={t('Series')} />}

                    {props.result.img
                        ? <img src={img} alt={props.result.title} onError={handleImgError} />
                        : <FaRegImage />}
                </div>

                <div>
                    <p>
                        {`${
                            props.result.title
                        }${
                            props.result.date ? ` (${new Date(props.result.date).getFullYear()})` : ''
                        }`}
                    </p>

                    <p>{props.result.overview}</p>
                </div>
            </Link>
        )
        : (
            <Link className='searchBarResultsRow normalSize' to={`/${i18n.language}/${props.result.type}/${props.result.id}`} tabIndex={0}
                title={`${
                    props.result.title
                }${
                    props.result.title === props.result.originalTitle ? '' : ` (${props.result.originalTitle})`
                }${
                    props.result.date ? ` (${new Date(props.result.date).getFullYear()})` : ''
                }${
                    props.result.overview ? `\n\n${props.result.overview}` : ''
                }`}
            >
                {<span>&bull;</span>}

                {props.result.img
                    ? <img src={img} alt={props.result.title} onError={handleImgError} />
                    : <FaRegImage />}

                <p>{props.result.title}</p>

                {props.result.type === 'movie' ? <FiFilm title={t('Movie')} /> : <FaTv title={t('Series')} />}
            </Link>
        );
}

SearchBarResultsRow.propTypes = {
    fullSize: PropTypes.bool.isRequired,
    result: PropTypes.exact({
        date: PropTypes.string,
        id: PropTypes.number.isRequired,
        img: PropTypes.string,
        originalTitle: PropTypes.string,
        overview: PropTypes.string,
        popularity: PropTypes.number,
        title: PropTypes.string,
        type: PropTypes.oneOf(['movie', 'tv']).isRequired
    }).isRequired
};

export default SearchBarResultsRow;