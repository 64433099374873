import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import { withRouter } from 'react-router-dom';

/**
 * This component tracks route changes and sends the new route to Google Analytics.
 */
function RouteChangeTracker({ history }) {
    history.listen(({ pathname }) => {
        ReactGA.send({ hitType: 'pageview', page: pathname });
    });

    return null;
}

RouteChangeTracker.propTypes = {
    history: PropTypes.objectOf(PropTypes.any).isRequired // eslint-disable-line react/forbid-prop-types
};

export default withRouter(RouteChangeTracker);