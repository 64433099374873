import './ContentResourcesRow.scss';

import { AiOutlineDisconnect } from 'react-icons/ai';
import { FaTv } from 'react-icons/fa';
import { FiAlertTriangle } from 'react-icons/fi';
import { getProvidersProps } from 'dhub-config';
import { IoMdDownload } from 'react-icons/io';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const providers = getProvidersProps(['types', 'urls']);
const longestProvider = Object.keys(providers).reduce((a, b) => a.length <= b.length ? b : a).length;

/**
 * This component renders each related resource, displaying all its data passed.
 */
function ContentResourcesRow(props) {
    const { t } = useTranslation();
    const types = [];
    const url = `${providers[props.resource.origin]?.urls[props.resource.alt || 0] || ''}${props.resource.url}`;

    (providers[props.resource.origin]?.types || []).forEach((type) => {
        switch (type) {
            case 'direct':
                types.push(<IoMdDownload key={type} title={t('Direct Download')} />);
                break;
            case 'torrent':
                types.push((
                    <svg key={type} fill='currentColor' height='1em' width='1em'>
                        <title>{t('Torrent Download')}</title>

                        <text x='20%' y='75%'>{'μ'}</text>
                    </svg>
                ));
                break;
            case 'online':
                types.push(<FaTv key={type} title={t('View Online')} />);
                break;
            default: break;
        }
    });

    return (
        <a className='contentResourcesRow' href={url} target='_blank' rel='noopener noreferrer' title={props.resource.name}>
            <div>{types.length ? types : <svg fill='currentColor' height='1em' width='1em' />}</div>

            <p>{props.resource.name}</p>

            {props.resource.providerStatus !== undefined && !props.resource.providerStatus
                && <div className='deletionAlert' title={t('Broken Provider')}><AiOutlineDisconnect /></div>}

            {props.resource.deletionAlert && <div className='deletionAlert' title={t('Broken Link')}><FiAlertTriangle /></div>}

            <p style={{ maxWidth: `${longestProvider * 0.9}ch` }}>{`${props.resource.origin}${props.resource.alt ? ' (alt)' : ''}`}</p>
        </a>
    );
}

ContentResourcesRow.propTypes = {
    resource: PropTypes.exact({
        alt: PropTypes.number,
        deletionAlert: PropTypes.string,
        name: PropTypes.string.isRequired,
        origin: PropTypes.string.isRequired,
        providerStatus: PropTypes.bool,
        url: PropTypes.string.isRequired
    }).isRequired
};

export default ContentResourcesRow;