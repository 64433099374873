import './SearchBarResults.scss';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import PropTypes from 'prop-types';
import React from 'react';
import SearchBarResultsRow from './SearchBarResultsRow';
import { useTranslation } from 'react-i18next';

/**
 * This component renders a container with multiple instances of SearchBarResultsRow (each result). Also, if there are no results
 * or an error, it renders a message stating this.
 */
function SearchBarResults(props) {
    const { t } = useTranslation();
    let content = null;

    if (props.results.length) {
        content = props.results.map((res) => <SearchBarResultsRow key={`${res.type}-${res.id}`} fullSize={props.fullSize} result={res} />);

        if (props.status === -1) {
            content.push(<div key='error'><p>{t('Network Error')}</p></div>);
        } else if (props.status === 0) {
            content.push(<div key='attribution'><img src={DOWNLOAD_HUB_TMDB_API.attributionImg} /></div>);
        } else if (props.status === 1) {
            content.push(<div key='loading'><AiOutlineLoading3Quarters /></div>);
        }
    } else if (props.status === -1) {
        content = <div><p>{t('Network Error')}</p></div>;
    } else if (props.status === 0) {
        content = <div><p>{t('No results were found')}</p></div>;
    } else if (props.status === 1) {
        content = <div><AiOutlineLoading3Quarters /></div>;
    }

    return (
        <div className={`searchBarResults${props.fullSize ? ' fullSize' : ''}`} tabIndex={-1}>
            <div onScroll={props.onScroll}>
                {content}
            </div>
        </div>
    );
}

SearchBarResults.propTypes = {
    fullSize: PropTypes.bool.isRequired,
    onScroll: PropTypes.func.isRequired,
    results: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired
    })).isRequired,
    status: PropTypes.oneOf([-1, 0, 1]).isRequired
};

export default SearchBarResults;