import './App.scss';

import { BrowserRouter, Link, Redirect, Route, Switch } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Collage from './collage/Collage';
import Content from './content/Content';
import LanguageDetector from './LanguageDetector';
import React from 'react';
import RouteChangeTracker from './RouteChangeTracker';
import SearchBar from './searchBar/SearchBar';
import ToggleThemeHandler from './ToggleThemeHandler';

function App() {
    return (
        <HelmetProvider>
            <BrowserRouter>
                <Helmet>
                    <title>{'DWTHub'}</title>
                </Helmet>

                <RouteChangeTracker />

                <Route path={['/', '/:locale', '/:locale/:type/:id']} component={LanguageDetector} exact />

                <Switch>
                    <Route path={['/', '/:locale']} component={Collage} exact />

                    <Route path='/:locale/:type/:id' component={Content} exact />

                    <Route path='*'>
                        <Redirect to='/' />
                    </Route>
                </Switch>


                <Link to='/' id='logo' className='logo'>
                    <img src={DOWNLOAD_HUB_FAVICON} alt='logo' />
                </Link>

                <ToggleThemeHandler />

                <SearchBar />
            </BrowserRouter>
        </HelmetProvider>
    );
}

export default App;