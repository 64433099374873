import './CollageCard.scss';

import { BsInfoCircle } from 'react-icons/bs';
import { FaTv } from 'react-icons/fa';
import { FiFilm } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

/**
 * This component renders a trending media card.
 */
class CollageCard extends React.Component {
    static propTypes = {
        i18n: PropTypes.objectOf(PropTypes.any).isRequired, // eslint-disable-line react/forbid-prop-types
        media: PropTypes.objectOf(PropTypes.any).isRequired, // eslint-disable-line react/forbid-prop-types
        t: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            details: false,
            img: `${DOWNLOAD_HUB_TMDB_API.posterImgL}${this.props.media.img}`,
            style: {}
        };

        this.handleMouseEnterCard = this.handleMouseEnterCard.bind(this);
        this.handleMouseLeaveCard = this.handleMouseLeaveCard.bind(this);
        this.handleMouseEnterInfo = this.handleMouseEnterInfo.bind(this);
        this.handleMouseLeaveInfo = this.handleMouseLeaveInfo.bind(this);
        this.handleImgError = this.handleImgError.bind(this);
    }

    handleMouseEnterCard(e) {
        while (e.target.className !== 'collageCard') {
            e.target = e.target.parentElement;
        }

        const rect = e.target.getBoundingClientRect();
        const bottomDistance = window.innerHeight - (rect.y + rect.height);
        const rightDistance = window.innerWidth - (rect.x + rect.width);

        this.setState({
            style: {
                bottom: Math.abs(bottomDistance) < rect.height * 0.66 && bottomDistance < 0 ? `${Math.abs(bottomDistance)}px` : undefined,
                left: rect.x < 0 ? `${Math.abs(rect.x)}px` : undefined,
                right: rightDistance < 0 ? `${Math.abs(rightDistance)}px` : undefined,
                top: rect.y < 0 ? `${Math.abs(rect.y)}px` : undefined
            }
        });
    }

    handleMouseLeaveCard() {
        this.setState({ style: {} });
    }

    handleMouseEnterInfo() {
        this.setState({ details: true });
    }

    handleMouseLeaveInfo() {
        this.setState({ details: false });
    }

    handleImgError() {
        if (!this.state.img.includes('?reload=true')) {
            this.setState((prevState) => ({ img: `${prevState.img}?reload=true` }));
        }
    }

    render() {
        const {
            date,
            id,
            originalTitle,
            overview,
            score,
            title,
            type,
            voteCount
        } = this.props.media;

        return (
            <Link className='collageCard' to={`/${this.props.i18n.language}/${type}/${id}`} tabIndex={-1} style={this.state.style}
                onMouseEnter={this.handleMouseEnterCard} onMouseLeave={this.handleMouseLeaveCard}
            >
                <div>
                    <p title={`${score} (${voteCount} ${this.props.t(voteCount === 1 ? 'vote' : 'votes')})`}>
                        {score > 0 && voteCount > 0 ? score.toFixed(1) : <strong>{'--'}</strong>}
                    </p>

                    <BsInfoCircle onMouseEnter={this.handleMouseEnterInfo} onMouseLeave={this.handleMouseLeaveInfo} />

                    <p title={new Date(date).toLocaleDateString(this.props.i18n.language)}>
                        {date && date.substr(0, 4)}
                    </p>

                    {type === 'movie'
                        ? <FiFilm title={this.props.t('Movie')} />
                        : <FaTv title={this.props.t('Series')} />}
                </div>

                {this.state.details && (
                    <div>
                        <p>{`${title}${title === originalTitle ? '' : ` (${originalTitle})`}`}</p>

                        <p>{`${overview ? `${overview}` : ''}`}</p>
                    </div>
                )}

                <img src={this.state.img} alt={title} onError={this.handleImgError} />
            </Link>
        );
    }
}

export default withTranslation()(CollageCard);