import './Collage.scss';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import CollageCard from './CollageCard';
import CollageError from './CollageError';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

/**
 * This component renders a collage of images with the trending media.
 */
class Collage extends React.Component {
    static propTypes = {
        i18n: PropTypes.objectOf(PropTypes.any).isRequired // eslint-disable-line react/forbid-prop-types
    }

    constructor(props) {
        super(props);

        const trending = JSON.parse(localStorage.getItem('trending'));

        this.opts = DOWNLOAD_HUB_COLLAGE_OPTS;
        this.cardWidth = 12; // Width of every CollageCard component in rem (it must be the same as the one in CollageCard.scss)

        this.state = {
            error: null,
            fetching: false,
            nextPage: trending && trending.lang === this.props.i18n.language && trending.expire > Date.now() ? trending.nextPage : 0,
            trending: trending && trending.lang === this.props.i18n.language && trending.expire > Date.now() ? trending.data : null
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    async componentDidMount() {
        document.getElementById('logo').style.position = 'fixed';
        document.getElementById('searchBar').style.position = 'fixed';

        window.addEventListener('scroll', this.handleScroll);

        if (!this.state.trending) {
            await this.fetchData();
        }

        if (window.innerWidth * window.innerHeight > 2100000 && this.state.nextPage <= 1) { // Res bigger than 1920x1080 and first page
            await this.fetchData();
        }

        if (window.innerWidth * window.innerHeight > 5000000 && this.state.nextPage <= 2) { // Res bigger than 3440x1440 and second page
            await this.fetchData();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        if (!this.fetching && this.state.nextPage < this.opts.pageLimit && (window.innerHeight + window.scrollY) === document.body.scrollHeight) {
            this.fetchData();
        }
    }

    async fetchData() {
        try {
            this.setState({ error: null, fetching: true });

            const trending = await (await fetch(`${DOWNLOAD_HUB_SERVER_URL}${DOWNLOAD_HUB_SERVER_API.trending}`
            + `?lang=${encodeURIComponent(this.props.i18n.language.slice(0, 2))}`
            + `${this.state.nextPage ? `&page=${encodeURIComponent(this.state.nextPage)}` : ''}`, {
                timeout: DOWNLOAD_HUB_REQ_TIMEOUT
            })).json();

            trending.sort(() => 0.5 - Math.random());

            this.setState((prevState) => ({
                fetching: false,
                nextPage: prevState.nextPage + 1,
                trending: prevState.trending
                    ? prevState.trending.concat(trending.filter((a) => !prevState.trending.find((b) => a.id === b.id && a.type === b.type)))
                    : trending
            }));

            return localStorage.setItem('trending', JSON.stringify({
                data: this.state.trending,
                expire: new Date(Date.now() + DOWNLOAD_HUB_CACHE_DURATION).getTime(),
                lang: this.props.i18n.language,
                nextPage: this.state.nextPage
            }));
        } catch (error) {
            return this.setState({ error, fetching: false });
        }
    }

    render() {
        const limit = this.state.trending && this.state.trending.length - (this.state.trending.length
            % Math.floor(window.innerWidth / (getComputedStyle(document.documentElement).fontSize.replace('px', '') * this.cardWidth)));

        if (this.state.error) {
            return <CollageError />;
        }

        return (
            <div className='collage'>
                {this.state.fetching && <AiOutlineLoading3Quarters />}

                {this.state.trending
                    && this.state.trending.map((media, i) => i < limit
                    && <CollageCard key={`${media.type}-${media.id}`} media={media} />)}
            </div>
        );
    }
}

export default withTranslation()(Collage);