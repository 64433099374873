import './ContentResources.scss';

import ContentResourcesRow from './ContentResourcesRow';
import { FaTv } from 'react-icons/fa';
import { getProvidersProps } from 'dhub-config';
import { IoMdDownload } from 'react-icons/io';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

const providersTypes = getProvidersProps(['types']);

/**
 * This component renders a container with multiple instances of ContentResourcesRow (each resource), generating tabs/filters for all
 * the types and origins present. If there are no resources, it renders a message stating this.
 */
class ContentResources extends React.Component {
    static propTypes = {
        i18n: PropTypes.objectOf(PropTypes.any).isRequired, // eslint-disable-line react/forbid-prop-types
        resources: PropTypes.arrayOf(PropTypes.shape({
            alt: PropTypes.number,
            name: PropTypes.string.isRequired,
            origin: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired
        })).isRequired,
        t: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            doubleClick: false,
            filter: 'all',
            providers: []
        };

        this.fetchProviders = this.fetchProviders.bind(this);

        this.handleFilterClick = this.handleFilterClick.bind(this);
        this.handleFilterDoubleClick = this.handleFilterDoubleClick.bind(this);

        this.generateFiltersCode = this.generateFiltersCode.bind(this);
        this.filterResources = this.filterResources.bind(this);
    }

    componentDidMount() {
        this.fetchProviders();
    }


    fetchProviders() {
        this.setState({ providers: [] });

        fetch(`${DOWNLOAD_HUB_SERVER_URL}${DOWNLOAD_HUB_SERVER_API.infoProviders}`, {
            timeout: DOWNLOAD_HUB_REQ_TIMEOUT
        }).then((res) => res.json())
            .then((providers) => this.setState({ providers }))
            .catch(() => this.setState({ providers: [] }));
    }


    handleFilterClick(event) {
        this.setState({ doubleClick: false, filter: event.target.getAttribute('data-value') });
    }

    handleFilterDoubleClick(event) {
        const dataValue = event.target.getAttribute('data-value');
        const uniqueProviderType = (providerType) => {
            const count = this.props.resources.reduce((acc, { origin }) => {
                if (providersTypes[origin].includes(providerType)) {
                    acc[0] += 1;
                    acc[1] += providersTypes[origin].length === 1 ? 1 : 0;
                }
                return acc;
            }, [0, 0]); // Given a provider type, it returns [<num of resources matching it>, <num of resources only matching it>]
            return count[1] && count[0] !== count[1];
        };

        if (this.state.filter !== dataValue) {
            this.setState({ doubleClick: false, filter: dataValue });
        } else if (uniqueProviderType(dataValue)) {
            this.setState((state) => ({ doubleClick: !state.doubleClick }));
        }
    }


    generateFiltersCode() {
        const status = (dataValue) => this.state.filter === dataValue ? `active${this.state.doubleClick ? ' doubleClick' : ''}` : null;
        const tabs = { origins: [], types: [] };

        this.props.resources.forEach(({ origin }) => {
            (providersTypes[origin] || []).forEach((type) => !tabs.types.includes(type) && tabs.types.push(type));

            if (origin && !tabs.origins.includes(origin)) {
                tabs.origins.push(origin);
            }
        });

        return [
            <div key='all' className={status('all')} data-value='all' onClick={this.handleFilterClick}>
                <p>{this.props.t('All')}</p>
            </div>,
            ...tabs.types.sort().map((type) => {
                const props = { 'className': status(type), 'data-value': type, 'key': type, 'onClick': this.handleFilterDoubleClick };
                const torrentSVG = <svg fill='currentColor' height='1em' width='1em'><text x='20%' y='60%'>{'μ'}</text></svg>;

                switch (type) {
                    case 'direct':
                        return <div {...props} title={this.props.t('Direct Download')}><IoMdDownload /></div>;
                    case 'torrent':
                        return <div {...props} title={this.props.t('Torrent Download')}>{torrentSVG}</div>;
                    case 'online':
                        return <div {...props} title={this.props.t('View Online')}><FaTv /></div>;
                    default:
                        return <div {...props}><p>{type}</p></div>;
                }
            }),
            ...tabs.origins.sort((a, b) => a.localeCompare(b, this.props.i18n.language)).map((origin) => (
                <div key={origin} className={status(origin)} data-value={origin} onClick={this.handleFilterClick}>
                    <p>{origin}</p>
                </div>
            ))
        ];
    }

    filterResources(resources) {
        return resources.filter(({ origin }) => this.state.filter === 'all' || (
            providersTypes[origin].includes(this.state.filter)
            && (!this.state.doubleClick || providersTypes[origin].length === 1)
        ) || this.state.filter === origin);
    }


    render() {
        return (
            <div className='contentResources'>
                {this.props.resources.length
                    ? (
                        <div>
                            <div className='contentResourcesFilters'>
                                {this.generateFiltersCode()}
                            </div>

                            {this.filterResources(this.props.resources)
                                .sort((a, b) => a.name.localeCompare(b.name, this.props.i18n.language)
                                    || a.origin.localeCompare(b.origin, this.props.i18n.language))
                                .map((resource) => {
                                    const key = `${resource.origin}-${resource.url}${resource.alt ? `-${resource.alt}` : ''}`;
                                    const originMatch = this.state.providers.filter(({ provider }) => provider === resource.origin);

                                    resource.providerStatus = originMatch.length ? originMatch[resource.alt || 0]?.status : undefined;

                                    return <ContentResourcesRow key={key} resource={resource} />;
                                })}
                        </div>
                    )
                    : <p>{this.props.t('No results were found')}</p>}
            </div>
        );
    }
}

export default withTranslation()(ContentResources);