import './ToggleThemeHandler.scss';

import { FiSun } from 'react-icons/fi';
import React from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';

/**
 * This component manages the theme toggle.
 */
function ToggleThemeHandler() {
    const [theme, setTheme] = React.useState(
        document.body.className = localStorage.getItem('theme') || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')
    );

    const { t } = useTranslation();

    function handleClick() {
        const newTheme = theme === 'light' ? 'dark' : 'light';

        setTheme(newTheme);
        localStorage.setItem('theme', newTheme);
        document.body.className = newTheme;

        ReactGA.event('change_theme', { category: 'Configuration', theme: newTheme });
    }


    return (
        <div className='themeToggle' title={t('Dark Mode')} onClick={handleClick}>
            <FiSun />
        </div>
    );
}

export default ToggleThemeHandler;