import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga4';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const langRegex = /^[a-z]{2}(?:-[a-z]{2})?$/iu;

/**
 * This component manages all language changes and loads the basic page description and keywords.
 * It also redirects to the default language if the user tries to access a page with an invalid language.
 */
function LanguageDetector(props) {
    const { i18n, t } = useTranslation();
    const { locale } = props.match.params;

    if (locale) {
        if (!langRegex.test(locale)) {
            ReactGA.event('change_language', { category: 'Warning', description: 'Invalid language', locale });
            return <Redirect to='/' />;
        } else if (locale !== i18n.language) {
            i18n.changeLanguage(locale);
            ReactGA.event('change_language', { category: 'Configuration', locale });
        }
    }

    return (
        <Helmet>
            <html lang={i18n.language.split('-')[0]} />

            <meta name='description' content={t('App description')} />

            <meta name='keywords' content={t('App keywords')} />
        </Helmet>
    );
}

LanguageDetector.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            locale: PropTypes.string
        }).isRequired
    }).isRequired
};

export default LanguageDetector;