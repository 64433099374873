import './CollageError.scss';

import { FaHeartBroken } from 'react-icons/fa';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * This component renders a container with a message stating there was an error.
 */
function CollageError() {
    const { t } = useTranslation();

    return (
        <div className='collageError'>
            <FaHeartBroken />

            <h1>{t('Website not available')}</h1>

            <p>{t('Under maintenance')}</p>
        </div>
    );
}

export default CollageError;