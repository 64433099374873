import './ContentError.scss';

import { Link, useHistory } from 'react-router-dom';

import { AiOutlineDisconnect } from 'react-icons/ai';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * This component renders a container with a message stating there was an error.
 */
function ContentError() {
    const { t } = useTranslation();
    const history = useHistory();

    function goBack() {
        history.goBack();
    }

    return (
        <div className='contentError'>
            <AiOutlineDisconnect />

            <h1>{t('Content not available')}</h1>

            <p>{t('Try again later')}</p>

            <Link to='/'>{t('Main Page')}</Link>

            <a onClick={goBack}>{t('Go Back')}</a>
        </div>
    );
}

export default ContentError;