import './ContentWatchResources.scss';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

/**
 * This component fetches the data for the media passed and renders all the watch resources.
 */
class ContentWatchResources extends React.Component {
    static propTypes = {
        i18n: PropTypes.objectOf(PropTypes.any).isRequired, // eslint-disable-line react/forbid-prop-types
        id: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
        type: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            rawRegions: [],
            rawResources: {},
            region: localStorage.getItem('region') || DOWNLOAD_HUB_DEFAULT_REGION,
            resources: null
        };

        this.fetchRegions = this.fetchRegions.bind(this);
        this.fetchResources = this.fetchResources.bind(this);
        this.handleRegionChange = this.handleRegionChange.bind(this);
        this.generateCode = this.generateCode.bind(this);
    }

    componentDidMount() {
        this.fetchRegions();
        this.fetchResources();
    }

    componentDidUpdate(prevProps) {
        if (this.props.i18n.language !== prevProps.i18n.language) {
            this.fetchRegions();
        }

        if (this.props.id !== prevProps.id || this.props.type !== prevProps.type) {
            this.fetchResources();
        }
    }


    fetchRegions() {
        this.setState({ rawRegions: [] });

        fetch(`${DOWNLOAD_HUB_SERVER_URL}${DOWNLOAD_HUB_SERVER_API.infoRegions}`
        + `?lang=${encodeURIComponent(this.props.i18n.language.slice(0, 2))}`, {
            timeout: DOWNLOAD_HUB_REQ_TIMEOUT
        }).then((res) => res.json())
            .then((rawRegions) => this.setState({ rawRegions }))
            .catch((error) => this.setState({ error, rawRegions: [] }));
    }

    fetchResources() {
        this.setState({ resources: null });

        fetch(`${DOWNLOAD_HUB_SERVER_URL}${DOWNLOAD_HUB_SERVER_API.mediaWatchResources}`
        + `?id=${encodeURIComponent(this.props.id)}`
        + `&type=${encodeURIComponent(this.props.type)}`, {
            timeout: DOWNLOAD_HUB_REQ_TIMEOUT
        }).then((res) => res.json())
            .then((res) => this.setState(({ region }) => ({ rawResources: res, resources: res[region] || {} })))
            .catch((error) => this.setState({ error, resources: {} }));
    }

    handleRegionChange(event) {
        const { rawResources } = this.state;

        this.setState({
            region: event.target.value,
            resources: rawResources[event.target.value] || {}
        });

        localStorage.setItem('region', event.target.value);
    }

    generateCode() {
        const removeDuplicates = (array) => {
            const ids = array.map(({ provider_id: id }) => id);
            return array.filter(({ provider_id: id }, index) => !ids.includes(id, index + 1));
        };

        const resources = {
            buy: this.state.resources.buy || [],
            rent: this.state.resources.rent || [],
            stream: removeDuplicates([
                ...this.state.resources.ads || [],
                ...this.state.resources.flatrate || [],
                ...this.state.resources.free || []
            ])
        };

        const outputTypes = ['stream', 'rent', 'buy'].filter((key) => resources[key]?.length > 0);

        return outputTypes.length > 0 && outputTypes.map((key) => (
            <div key={key}>
                <p>{this.props.t(key)}</p>

                <div>
                    {resources[key].map(({
                        provider_id: id,
                        logo_path: logo,
                        provider_name: name
                    }) => <img key={id} src={`${DOWNLOAD_HUB_TMDB_API.logoImg}${logo}`} alt='' title={name} />)}
                </div>
            </div>
        ));
    }


    render() {
        if (this.state.error) {
            return null;
        } else if (!this.state.resources) {
            return (
                <div className='contentWatchResources'>
                    <AiOutlineLoading3Quarters />
                </div>
            );
        }

        return (
            <div className='contentWatchResources'>
                <select name='region' value={this.state.region} onChange={this.handleRegionChange}>
                    {this.state.rawRegions.sort(({ native_name: a }, { native_name: b }) => a.localeCompare(b, this.props.i18n.language))
                        .map(({ iso_3166_1: code, native_name: name }) => <option key={code} value={code}>{name}</option>)}
                </select>

                <div>
                    {this.generateCode() || <p>{this.props.t('No results were found')}</p>}
                </div>
            </div>
        );
    }
}

export default withTranslation()(ContentWatchResources);